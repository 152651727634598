import store from 'app/store';

import { selectAuthToken } from 'features/auth/authSelectors';

import checkStatus from 'utils/api/checkStatus';
import errorParser from 'utils/api/errorParser';
import responseParser from 'utils/api/responseParser';

import { HOST } from 'constants/endpoints';

interface APIConfig {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: HeadersInit;
  body?: string | FormData;
  includeContentType?: boolean;
}

const fetchApi = (
  path: string,
  config: APIConfig,
  isFile = false
): Promise<any> => {
  const token = selectAuthToken(store.getState());
  const headers: HeadersInit = {
    ...(config.includeContentType !== false &&
    !(config.body instanceof FormData)
      ? { 'Content-Type': 'application/json' }
      : {}),
    ...(token ? { Authorization: token } : {}),
    ...config.headers
  };

  const fullConfig: RequestInit = {
    ...config,
    headers: headers
  };

  const url = `${HOST}${path}`;

  return fetch(url, fullConfig)
    .then(checkStatus)
    .then((data) => responseParser(data, isFile))
    .catch(errorParser);
};

export const get = (path: string, headers?: HeadersInit): Promise<any> =>
  fetchApi(path, { method: 'GET', headers });

export const getFile = (path: string): Promise<Blob> =>
  fetchApi(path, { method: 'GET', includeContentType: false }, true);

export const post = (
  path: string,
  body: object,
  headers?: HeadersInit
): Promise<any> =>
  fetchApi(path, { method: 'POST', body: JSON.stringify(body), headers });

export const postFormData = (
  path: string,
  body: FormData,
  headers?: HeadersInit
): Promise<any> => fetchApi(path, { method: 'POST', body, headers });

export const put = (
  path: string,
  body: object,
  headers?: HeadersInit
): Promise<any> =>
  fetchApi(path, { method: 'PUT', body: JSON.stringify(body), headers });

export const del = (path: string, headers?: HeadersInit): Promise<any> =>
  fetchApi(path, { method: 'DELETE', headers });

export default { get, post, postFormData, put, getFile, del };
