import { ReactElement } from 'react';
import { useRoutes } from 'react-router-dom';

import getRoutes from 'routes';
import ProtectedRoute from 'routes/components/ProtectedRoute';

import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';

import { RouteWithChildren } from 'types/route';

const RenderRoutes = (): ReactElement | null => {
  const routes: RouteWithChildren[] = [
    ...getRoutes().map((route: RouteWithChildren) => ({
      ...route,
      element: route.protectedRoute ? (
        <ProtectedRoute>{route.element}</ProtectedRoute>
      ) : (
        route.element
      ),
      children: route.children
        ? route.children.map((child: RouteWithChildren) => ({
            ...child,
            element: child.protectedRoute ? (
              <ProtectedRoute>{child.element}</ProtectedRoute>
            ) : (
              child.element
            )
          }))
        : []
    })),
    {
      path: '*',
      key: 'NOT_FOUND',
      element: <NotFoundPage />
    }
  ];

  return useRoutes(routes);
};

export default RenderRoutes;
