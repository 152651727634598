import { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import 'styles/Main.scss';

import store from 'app/store';

import App from './App';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);

const Main = (): ReactElement => (
  <Provider store={store}>
    <App />
  </Provider>
);

root.render(<Main />);
