import { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { App as AntApp, ConfigProvider } from 'antd';
import RenderRoutes from 'routes/components/RenderRoutes';

import Loader from 'layouts/Suspense';

import theme from './config/antdThemeConfig';

const App: FC = () => {
  return (
    <ConfigProvider theme={theme}>
      <AntApp>
        <Loader>
          <Router>
            <RenderRoutes />
          </Router>
        </Loader>
      </AntApp>
    </ConfigProvider>
  );
};

export default App;
