import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import AuthLayout from 'layouts/AuthLayout';

const RouteAuthLayout = ({ config = {} }): ReactElement => (
  <AuthLayout {...config}>
    <Outlet />
  </AuthLayout>
);

export default RouteAuthLayout;
