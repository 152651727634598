import { get, post, put } from 'utils/api/api';

import { GET_USER, LOGIN } from 'constants/endpoints';

interface LoginData {
  email: string;
  password: string;
}

class AuthService {
  async getUser(): Promise<any> {
    const response = await get(GET_USER);
    return response;
  }

  async login(data: LoginData): Promise<any> {
    const response = await post(LOGIN, data);
    return response;
  }
}

export default new AuthService();
