import { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { App } from 'antd';

import { selectIsLoggedIn } from 'features/auth/authSelectors';

import { AUTH } from 'constants/path';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps): ReactNode => {
  const isAuthenticated = useSelector(selectIsLoggedIn);
  const { notification } = App.useApp();

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     notification.info({
  //       message:
  //         'You must be logged in to access this page. Please login to continue.',
  //       placement: 'topRight',
  //       duration: 4000
  //     });
  //   }
  // }, [isAuthenticated, notification]);

  if (!isAuthenticated) {
    return <Navigate to={AUTH} replace />;
  }

  return children;
};

export default ProtectedRoute;
