import { ReactElement, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import cs from 'classnames';
import getRoutes from 'routes';

import Header from 'components/Header/Header';

import { LayoutProps, RouteObject } from 'types/route';

import styles from './styles.module.scss';

const BaseLayout = ({ children }: LayoutProps): ReactElement => {
  const { pathname } = useLocation();
  const routes = getRoutes();

  const currentRoute = useMemo(
    () => routes.find((route: RouteObject) => route.path === pathname),
    [pathname, routes]
  );

  return (
    <>
      <Layout className={cs(currentRoute?.key)}>
        <Header />
        <Content className={styles.container}>{children}</Content>
      </Layout>
    </>
  );
};

export default BaseLayout;
