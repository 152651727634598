import { Middleware, UnknownAction } from '@reduxjs/toolkit';
import { logout } from 'features/auth/authSlice';

interface RejectedAction extends UnknownAction {
  payload?: {
    status: number;
  };
}

// @ts-ignore
const apiMiddleware: Middleware = (store) => (next) => (action: RejectedAction) => {
  if (action.type.endsWith('/rejected') && action.payload?.status === 401) {
    store.dispatch(logout());
  }
  return next(action);
};

export default apiMiddleware;
