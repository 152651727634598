import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from 'features/auth/authService';

export const fetchUserData = createAsyncThunk(
  'auth/fetchUserData',
  async (_, { rejectWithValue }) => {
    try {
      const user = await AuthService.getUser();
      return { ...user };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
