import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User } from 'types/auth';

import { fetchUserData } from './authThunks';

const tokenFromStorage = localStorage.getItem('token');

const initialState = {
  token: tokenFromStorage,
  isLoggedIn: !!tokenFromStorage,
  user: {},
  isLoading: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token;
      state.isLoggedIn = true;
      localStorage.setItem('token', action.payload.token);
    },
    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      state.user = {
        ...state.user,
        ...action.payload
      };
    },
    logout: (state) => {
      state.token = null;
      state.isLoggedIn = false;
      state.user = {};
      localStorage.removeItem('token');
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchUserData.fulfilled,
      (state, action: PayloadAction<User>) => {
        state.user = {
          ...state.user,
          ...action.payload
        };
        state.isLoading = false;
      }
    );
    builder.addCase(fetchUserData.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const { setCredentials, updateUser, logout } = authSlice.actions;

export default authSlice.reducer;
