import { ReactElement, lazy } from 'react';

import RouteAuthLayout from 'routes/components/RouteAuthLayout';
import RouteWithLayout from 'routes/components/RouteWithLayout';

import { AUTH, MAIN } from '../constants/path';

const MainPage = lazy(() => import('pages/Main/Main'));
const AuthPage = lazy(() => import('pages/Auth/Auth'));

type RouteObject = {
  path: string;
  key: string;
  element: ReactElement;
  protectedRoute?: boolean;
  children?: RouteObject[];
};

const createRoute = (
  path: string,
  key: string,
  element: ReactElement,
  protectedRoute: boolean,
  children: RouteObject[] = []
): RouteObject => ({
  path,
  key,
  protectedRoute,
  element,
  children
});

const getRoutes = (): RouteObject[] => [
  createRoute(MAIN, 'MAIN_ROOT', <RouteWithLayout />, true, [
    createRoute(MAIN, 'MAIN', <MainPage />, true)
  ]),
  createRoute(MAIN, 'AUTH_ROOT', <RouteAuthLayout />, false, [
    createRoute(AUTH, 'AUTH', <AuthPage />, false)
  ])
];

export default getRoutes;
