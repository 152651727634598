const responseParser = async (
  response: Response,
  isBlob = false
): Promise<unknown> => {
  let result: unknown;

  try {
    if (isBlob) {
      result = await response.blob();
    } else {
      result = await response.json();
    }
  } catch {
    result = response;
  }

  return result;
};

export default responseParser;
