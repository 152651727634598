import { ReactElement, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Content } from 'antd/es/layout/layout';
import cs from 'classnames';
import getRoutes from 'routes';

import { LayoutProps, RouteObject } from 'types/route';

import styles from './styles.module.scss';

const AuthLayout = ({ children }: LayoutProps): ReactElement => {
  const { pathname } = useLocation();
  const routes = getRoutes();

  const cursorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (cursorRef.current) {
        cursorRef.current.style.setProperty('--x', `${event.clientX}px`);
        cursorRef.current.style.setProperty('--y', `${event.clientY}px`);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const currentRoute = useMemo(
    () => routes.find((route: RouteObject) => route.path === pathname),
    [pathname, routes]
  );

  return (
    <div className={styles.authContainer}>
      <div className={styles.decorationContainer}>
        <h1 className={styles.mainTitle}>JAIVIN</h1>
        <p className={styles.subTitle}>CONSOLE</p>
        <div className={styles.cursor} ref={cursorRef}></div>
      </div>
      <div className={cs(styles.funcContainer, currentRoute?.key)}>
        <Content>{children}</Content>
      </div>
    </div>
  );
};

export default AuthLayout;
